import React from "react";


const Footer=()=>{
    return (
        <footer className="footer">
            <div>
                <ul className="text-center mb-2">
                <img src="/images/logo1.png" className="mb-3 mt-2" id="footer-logo"  alt=""/>
                <li><i className="fa fa-phone"></i> +381 63 836 6155</li>
                <li><i className="fa fa-user"></i> Branko Mihailović</li>
                <li><i className="fa fa-map-marker"></i>15300 Loznica</li>
                <li><strong> email: ppa.ecoplastex@gmail.com</strong></li>
                <li> <a href="https://www.facebook.com/Papirne-kese-i-PVC-ambalaza-581518462541157/" target="_blanck" className="mr-3 "><i className="fa fa-facebook-square fa-2x" ></i></a>
                                <a href="https://www.instagram.com/eco_plastex/" target="_blanck" ><i className="fa fa-instagram fa-2x"></i></a>   </li>
                </ul>
            </div>
            <div className="text-right pr-2 footerend">
                <p className="mb-0">&copy; 2019. ECO PLASTEX. Sva prava zadržana</p>
                <p className="pb-1 mt-0 pr-2 mb-0 text-right"><small>Made by Veljko Ranković</small></p>
            </div>
        </footer>
    )
};

export default Footer;