import React from "react";
import {connect} from "react-redux";
import {fetchPosts} from "../../actions/index"
import Footer from "./Footer"
import {Link} from "react-router-dom"

class SpecifikacijaProizvoda extends React.Component{
    componentDidMount(){
        this.props.fetchPosts()
    }

    renderOther(){
        return this.props.proizvodi.map(proizvod=>{
            if(proizvod.kategorija===this.props.proizvod.kategorija && proizvod.id !==this.props.proizvod.id){
                 return(
                     <div key={proizvod.id}>
                         <Link className="link" to={`/specifikacijaproizvoda${proizvod.tag}/${proizvod.id}`}>
                         <div className="grid-item">
                         <div className="card cards" >
                         <div className="card-body">
                             <h5 className="card-title text-center">{proizvod.naziv}</h5>
                             <p className="text-center card-text">{proizvod.dimenzija}</p>
                         </div>
                             <div className="m-1">
                                 <img src={proizvod.img} className="card-img-top" alt="..."/>
                             </div>
                         </div>
                         </div>
                         </Link>
                     </div>
            )}
            return null
        })
    }

    
    renderContent(){
        if(!this.props.proizvod){
            return (
            <div className="text-center mb-5 mt-5" style={{height:"250px"}}>
                <h1 className="">Stranica nije pronađena</h1>
                <Link className="link-btn" to="/">Nazad na početnu</Link>

            </div>
            )
        }
          return (
            <div className="container mt-5">
                <div className="row">
                <div className="col-lg-5 text-center mt-5">
                    <img src={this.props.proizvod.img} style={{maxWidth:"100%"}}  alt=""/>
                    
                    
                </div>
                <div className="col-lg-7 part2">
                    <h2 className="text-center">{this.props.proizvod.naziv}</h2>
                    <hr/>
                    <p>{this.props.proizvod.opis}</p>
                    <hr/>
                    <h5>Specifikacija:</h5>
                    <p className="mb-0 pb-1">Dimenzija: {this.props.proizvod.dimenzija}</p>
                    <p className="mb-0 pb-1">Materijal: {this.props.proizvod.materijal}</p>
                    <p className="mb-0 pb-1">Boja: {this.props.proizvod.boja}</p>
                    <p className="mb-0 pb-1">Tiraž: {this.props.proizvod.tiraz}</p>
                    <div className="mb-5 text-right">
                    <Link to="/kontakt" className=" link-btn" href="kontakt.html">Kontaktirajte nas <i className="fa fa-arrow-right"></i></Link>
                    </div>
                </div>
                </div>
                <div>
                <h3 className="text-center">Slični proizvodi:</h3>
                <div className="grid-container">{this.renderOther()}</div>
                </div>
                
          </div>
          )
      }
    
    render(){
        
      return (
        <div className="wrapper">
            <div className="row" id="navbar">
                <div className="col-md-1 col-5">
                    <Link to="/">
                    <img className=" pl-3 pt-2 pb-1" id="logo" src="/images/logo1.png" alt=""/>
                    </Link>
                </div>
                <div className="col-md-11 col-7 text-right mt-2">
                <div>
                <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-end pt-4" > 
                    <button className="navbar-toggler ml-5" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse ml-5 " id="navbarSupportedContent">
                    <ul className="navbar-nav ml-3" id="navbar" >
                        <li className="nav-item navItem ">
                        <Link className="nav-link"  to="/">POČETNA</Link>
                        </li>
                        <li className="nav-item navItem">
                        <Link className="nav-link" to="/onama">O NAMA</Link>
                        </li>
                        <li className="nav-item navItem ">
                        <Link className="nav-link" to="/papirnekesesastampom">PAPIRNE KESE SA ŠTAMPOM</Link>
                        </li>
                        
                        <li className="nav-item dropdown navItem">
                        <p className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            PAPIRNE KESE
                        </p>
                        <div className="dropdown-menu p-0" id="dropdown-item" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to="/papirnekesesaravnomruckom" >SA RAVNOM RUČKOM:</Link>
                            <Link className="dropdown-item pl-5 " to="/papirnekesesaunutrasnjomruckom">- UNUTRAŠNJOM</Link>
                            <Link className="dropdown-item pl-5" to="/papirnekesesaspoljasnjomruckom">- SPOLJAŠNJOM</Link>
                            <hr className="m-0 p-0"/>
                            <Link className="dropdown-item" to="/papirnekesesaupredenomruckom">SA UPREDENOM RUČKOM</Link>
                            <hr className="m-0 p-0"/>
                            <Link className="dropdown-item" to="/papirnekesebezrucke">BEZ RUČKE</Link>
                        </div>
                        </li>
                        <li className="nav-item navItem">
                        <Link className="nav-link" to="/picakutije">PIZZA KUTIJE SA ILI BEZ ŠTAMPE</Link>
                        </li>
                        <li className="nav-item dropdown navItem">
                        <p className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            PVC
                        </p>
                        <div className="dropdown-menu p-0" id="dropdown-item" aria-labelledby="navbarDropdown">
                            <Link className="dropdown-item" to="/pvckese" >KESE</Link>
                            <hr className="m-0 p-0"/>
                            <Link className="dropdown-item" to="/pvcvrece">VREĆE</Link>
                        </div>
                        </li>
                        
                        <li className="nav-item navItem">
                        <Link className="nav-link" to="/kontakt">KONTAKT</Link>
                        </li>
                    </ul>      
                    </div>
                </nav>
                </div>  
            </div>
            </div>
            <hr className="m-0 mt-1"></hr>
            <div>{this.renderContent()}</div>
        <Footer/>
    </div> 
      )
}

}
const mapStateToProps=(state,ownProps)=>{
    return{proizvod:state.proizvodi[ownProps.match.params.id],
     proizvodi:Object.values(state.proizvodi)}
}

export default connect(mapStateToProps,{fetchPosts}) (SpecifikacijaProizvoda);