import React from "react";
import {Link} from "react-router-dom"
import {Slide, Fade} from "react-slideshow-image"

import Footer from "./Footer"

const slide1=[
    "./images/slikakesa1234.jpg",
    "./images/kese3.jpg"
]

const fadeProperties = {
    duration: 5000,
    transitionDuration: 1000,
    infinite: true,
    indicators: false,
    arrows:true
  }
const Slide1=()=>{
    return(
        <div className="slide-container slide1">
            <Fade {...fadeProperties}>
                <div className="each-fade">
                    <img src={slide1[0]} style={{width:"100%", maxHeight:"370px"}} alt=""></img>
                </div>
                <div className="each-fade">
                    <img src={slide1[1]} style={{width:"100%", maxHeight:"370px"}} alt=""></img>
                </div>
            </Fade>
        </div>
    )
}

const slideImages=[
    "/images/kesabezrucke1.jpg",
    "/images/reklama6.png",
    "/images/kesaravnaiznutra2.jpg",
    "/images/bela-papirna-kesa.jpg",
    "/images/kesaravnaspolja2.jpg",
    "/images/bela-papirna-kesa1.jpg",
    "/images/reklama1.png",
    "/images/kesaravnaspolja3.jpg",
    "/images/kesabezrucke4.jpg",
    "/images/kesaupredena3.jpg",
    "/images/kesaravnaiznutra3.jpg",
    "/images/kesabezrucke3.jpg",

]

const height=window.innerWidth/4.5
const properties={
    duration:1,
    transitionDuration:4000,
    infinite:true,
    indicators:false,
    arrows:false,
}
const Slideshow = () => {
    return (
      <div className="slide-container text-center">
        <Slide {...properties}>
          <div className="each-slide">
              <img src={slideImages[0]} alt="" style={{width:height}}></img>
              <img src={slideImages[1]} alt="" style={{width:height}}></img>
              <img src={slideImages[2]} alt="" style={{width:height}}></img>
              <img src={slideImages[3]} alt="" style={{width:height}}></img>

          </div>
          <div className="each-slide">
              <img src={slideImages[4]} alt="" style={{width:height}}></img>
              <img src={slideImages[5]} alt="" style={{width:height}}></img>
              <img src={slideImages[6]} alt="" style={{width:height}}></img>
              <img src={slideImages[7]} alt="" style={{width:height}}></img>
          </div>
          <div className="each-slide">
              <img src={slideImages[8]} alt="" style={{width:height}}></img>
              <img src={slideImages[9]} alt="" style={{width:height}}></img>
              <img src={slideImages[10]} alt="" style={{width:height}}></img>
              <img src={slideImages[11]} alt="" style={{width:height}}></img>
          </div>
        </Slide>
      </div>
    )
}

class MainPage extends React.Component{
    render(){
        return (
            <div className="wrapper">
                <div className="row" id="navbar">
                    <div className="col-md-1 col-5">
                        <Link to="/">
                        <img className=" pl-3 pt-2 pb-1" id="logo" src="./images/logo1.png" alt=""/>
                        </Link>
                    </div>
                    <div className="col-md-11 col-7 text-right mt-2">
                        
                    <div>
                    <nav className="navbar navbar-expand-xl navbar-light d-flex justify-content-end pt-4" > 
                        <button className="navbar-toggler ml-5" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse ml-5 " id="navbarSupportedContent">
                        <ul className="navbar-nav ml-3" id="navbar" >
                            <li className="nav-item navItem ">
                            <Link className="nav-link" id="active" to="/">POČETNA</Link>
                            </li>
                            <li className="nav-item navItem">
                            <Link className="nav-link" to="/onama">O NAMA</Link>
                            </li>
                            <li className="nav-item navItem ">
                            <Link className="nav-link" to="/papirnekesesastampom">PAPIRNE KESE SA ŠTAMPOM</Link>
                            </li>
                            
                            <li className="nav-item dropdown navItem">
                            <p className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                PAPIRNE KESE
                            </p>
                            <div className="dropdown-menu p-0" id="dropdown-item" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to="/papirnekesesaravnomruckom" >SA RAVNOM RUČKOM:</Link>
                                <Link className="dropdown-item pl-5 " to="/papirnekesesaunutrasnjomruckom">- UNUTRAŠNJOM</Link>
                                <Link className="dropdown-item pl-5" to="/papirnekesesaspoljasnjomruckom">- SPOLJAŠNJOM</Link>
                                <hr className="m-0 p-0"/>
                                <Link className="dropdown-item" to="/papirnekesesaupredenomruckom">SA UPREDENOM RUČKOM</Link>
                                <hr className="m-0 p-0"/>
                                <Link className="dropdown-item" to="/papirnekesebezrucke">BEZ RUČKE</Link>
                            </div>
                            </li>
                            <li className="nav-item navItem">
                            <Link className="nav-link" to="/picakutije">PIZZA KUTIJE SA ILI BEZ ŠTAMPE</Link>
                            </li>
                            <li className="nav-item dropdown navItem">
                            <p className="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                PVC
                            </p>
                            <div className="dropdown-menu p-0" id="dropdown-item" aria-labelledby="navbarDropdown">
                                <Link className="dropdown-item" to="/pvckese" >KESE</Link>
                                <hr className="m-0 p-0"/>
                                <Link className="dropdown-item" to="/pvcvrece">VREĆE</Link>
                            </div>
                            </li>
                            
                            <li className="nav-item navItem">
                            <Link className="nav-link" to="/kontakt">KONTAKT</Link>
                            </li>
                        </ul>      
                        </div>
                    </nav>
                    </div>  
                </div>
                </div>
                <hr className="m-0 mt-1"></hr>
                <div className="part1">
                <div className="part1-container">
                    <div className="row ">
                        <div className="col-md-6  text-center  mb-5 mt-5">
                            <img src="./images/naslovnaKesa.png"  alt=""/>
                        </div>
                        <div className="col-md-6  mt-5   ">
                            <div className="main-title">
                                <h3 className="">  Štampa na papirnim kesama i pizza kutijama, papirne kese i proizvodnja plastične ambalaže</h3>
                                <h1 className="" id="main-text">Vaša reklama je naša briga!!!</h1>
                                <h5 className="">  Tiraž do 5 000 komada</h5>
                                <Link to="/onama"  className=" btn button mt-5 mb-3">Detaljnije >>></Link>
                            </div>    
                        </div>
                    </div>
                </div>
            </div> 
                <div className="part2 mt-5">
                <Slide1></Slide1>
                    <div className="part2-container mt-5">
                        <h1 className="text-center mt-5">Papirne kese</h1>
                        <div className="row">
                            <div className="col-md-3 col-6 m-0 p-0 "> 
                                <div className="card cards" >
                                    <div className="m-1">
                                    <Link to="/papirnekesesaunutrasnjomruckom">
                                        <img src="./images/kesaravnaiznutra2.jpg" className="card-img-top" alt="..."/>
                                    </Link>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title text-center">Papirne kese sa unutrašnjom ručkom</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 m-0 p-0"> 
                                <div className="card cards" >
                                    <div className="m-1">
                                        <Link to="/papirnekesesaspoljasnjomruckom">
                                            <img src="./images/bela-papirna-kesa1.jpg" className="card-img-top" alt="..."/>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title text-center">Papirne kese sa spoljašnjom ručkom</h5>
                                    </div>
                                </div> 
                                
                            </div>
                            <div className="col-md-3 col-6 m-0 p-0"> 
                                <div className="card cards" >
                                        <div className="m-1">
                                            <Link to="/papirnekesesaupredenomruckom">
                                            <img src="./images/kesaupredena1.jpg" className="card-img-top" alt="..."/>
                                            </Link>
                                        </div>
                                        <div className="card-body">
                                        <h5 className="card-title text-center">Papirne kese sa upredenom ručkom</h5>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-md-3 col-6 m-0 p-0"> 
                                <div className="card cards" >
                                    <div className="m-1">
                                        <Link to="/papirnekesebezrucke">
                                            <img src="./images/kesabezrucke1.jpg" className="card-img-top" alt="..."/>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                    <h5 className="card-title text-center">Kese bez ručke <br></br><br></br></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <br/>
                <Slideshow></Slideshow>
                <br/>
                <div className="part3">
                    <div className="part3-container">
                        <h1 className="text-center">Takođe u ponudi</h1>
                        <div className="row align-items-end">
                            <div className="col-md-4 ">
                                <div className="card cards " id="card" >
                                    <div className="m-1">
                                        <Link to="/pvckese">
                                            <img src="./images/pvckese2.jpg" className="card-img-top" alt="..."/>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title text-center">PVC kese</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card cards" id="card1" >
                                    <div className="m-1">
                                        <Link to="/picakutije">
                                            <img src="./images/picakutija1.jpg" className="card-img-top" alt="..."/>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title text-center">Pizza kutije sa ili bez štampe</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card cards " id="card" >
                                    <div className="m-1">
                                        <Link to="/pvcvrece">
                                            <img src="./images/pvcvrece1.jpg" className="card-img-top" alt="..."/>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title text-center">PVC vreće</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
            </div> //end of wrapper
        );
    }
}

export default MainPage;